import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Flex, Box, Text } from "@chakra-ui/react"
import Layout from "../components/Layout";
import CustomContainer from "../components/UI/CustomContainer"
import Markdown from "../components/UI/Markdown"
import CustomLink from "../components/UI/CustomLink"

const Legals = props => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: {settingName: {eq: "legals"}}) {
          frontmatter {
            legalName
            legalDirector
            legalAddress
            legalPhone
            legalEmail
            legalSIREN
            legalTVA
            legalCompany {
              legalStatus
              legalCapital
            }
            legalCopyright
          }
        }
      }
    `
  );
  const data = markdownRemark.frontmatter || {};
  const empty = "En cours de rédaction"
  const legalDirector = data.legalDirector || empty
  const legalAddress = data.legalAddress || empty
  const legalPhone = data.legalPhone || empty
  const legalEmail = data.legalEmail || empty
  const legalSIREN = data.legalSIREN || empty
  return (
    <Layout title="Mentions Légales">
      <CustomContainer
      py={[10,16,20]}
      sx={{
        a: {
          textDecoration: "underline"
        }
      }}>
        <Box>
          <Text as="h2" mt="0">Éditeur</Text>
          {data.legalName && (
            <Text>{data.legalName}</Text>
          )}
          {legalDirector && (
            <Text>Directeur/directrice de la publication : {legalDirector}</Text>
          )}
          {legalAddress && (
            <Flex>
              <Text mr={2}>Adresse :</Text>
              <Markdown>{legalAddress}</Markdown>
            </Flex>
          )}
          {legalPhone && (
            <Text>Téléphone : {legalPhone}</Text>
          )}
          {legalEmail && (
            <Text>Email : {legalEmail}</Text>
          )}
          {legalSIREN && (
            <Text>SIREN : {legalSIREN}</Text>
          )}
          {data.legalTVA && (
            <Text>Numéro de TVA : {data.legalTVA}</Text>
          )}
          {data.legalCompany && (
            data.legalCompany.legalStatus &&
            <Text>Forme juridique : {data.legalCompany.legalStatus}</Text>
          )}
          {data.legalCompany && (
            data.legalCompany.legalCapital &&
            <Text>Capital social : {data.legalCompany.legalCapital}</Text>
          )}
        </Box>
        <Box>
          <Text as="h2">Hébergement</Text>
          <Text>Netlify</Text>
          <Text>325 3rd Street, Suite 296<br />San Francisco, California 94107</Text>
          <CustomLink href="https://www.netlify.com">https://www.netlify.com</CustomLink>
        </Box>
        {data.legalCopyright && (
          <Box>
            <Text as="h2">Crédits</Text>
            <Markdown>{data.legalCopyright}</Markdown>
          </Box>
        )}
      </CustomContainer>
    </Layout>
  );
};

export default Legals
